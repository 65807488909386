//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      loaderVisible: this.loading,
      bodyVisible: !this.loading
    };
  },
  watch: {
    loading: function loading(_loading) {
      var _this = this;

      this.loaderVisible = _loading;
      setTimeout(function () {
        _this.bodyVisible = !_loading;
      }, 300);
    }
  }
};